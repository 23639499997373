// Generated by .maintenance/generators/routes.js
import AppLocaleDefault from "src/x3000.ee/locales/et.json";

export default [
  {
    name: "maintenance-et",
    path: "/",
    component: () => import("src/.maintenance/views/default.vue"),
    meta: {
      title: "x3000.ee",
      localeLoader: () => new Promise(resolve => resolve(AppLocaleDefault))
    }
  },
  {
    name: "restricted-et",
    path: "/restricted",
    component: () => import("src/.maintenance/views/restricted.vue"),
    meta: {
      title: "x3000.ee - restricted",
      localeLoader: () => new Promise(resolve => resolve(AppLocaleDefault))
    }
  },
  {
    name: "maintenance-ru",
    path: "/ru/",
    component: () => import("src/.maintenance/views/default.vue"),
    meta: {
      title: "x3000.ee",
      localeLoader: () => import("../x3000.ee/locales/ru.json")
    }
  },
  {
    name: "restricted-ru",
    path: "/ru/restricted",
    component: () => import("src/.maintenance/views/restricted.vue"),
    meta: {
      title: "x3000.ee - restricted",
      localeLoader: () => import("../x3000.ee/locales/ru.json")
    }
  },
  {
    name: "maintenance-en",
    path: "/en/",
    component: () => import("src/.maintenance/views/default.vue"),
    meta: {
      title: "x3000.ee",
      localeLoader: () => import("../x3000.ee/locales/en.json")
    }
  },
  {
    name: "restricted-en",
    path: "/en/restricted",
    component: () => import("src/.maintenance/views/restricted.vue"),
    meta: {
      title: "x3000.ee - restricted",
      localeLoader: () => import("../x3000.ee/locales/en.json")
    }
  },
  {
    path: "*",
    component: () => import("src/.maintenance/views/404.vue"),
    meta: {
      title: "x3000.ee - 404"
    }
  }
];
